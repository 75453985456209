<template>
  <q-step
    :name="2"
    title="Kubernetes settings"
    icon="settings"
    :done="this.update_step > 2"
  >
    <q-form ref="kubernetes-settings-form">
      <div class="q-mb-sm">Select the Kubernetes distribution</div>
      <k8s-distribution-card
        v-for="k8sFlavoursOptions in k8sFlavoursOptions"
        @card-clicked="onCardClicked(k8sFlavoursOptions.value)"
        :key="k8sFlavoursOptions.value"
        class="q-pa-md"
        :data="k8sFlavoursOptions"
        :isSelected="k8sFlavoursOptions.value === kubernetesFlavour"
      />
      <div style="color: red" v-if="showError">Select a distribution</div>
      <div class="row" style="margin-top: 30px">
        <div>
          <q-select
            label="Kubernetes version (Optional)"
            :disable="kubernetesFlavour === null"
            v-model="kubernetesVersion"
            :options="kubernetesVersionOptions"
            style="margin-bottom: 20px; width: 260px"
            @update:model-value="$emit('kubernetes_version', $event)"
            class="col-8"
          />
          <q-tooltip :delay="1000">
            This is the version of Kubernetes that will be installed.<br />
            If you do not select a version, the latest (and supported by Run:AI)
            version will be installed.
          </q-tooltip>
        </div>
        <div>
          <q-select
            label="Region"
            v-model="region"
            :options="regionOptions"
            style="margin-bottom: 20px; width: 250px; margin-left: 40px"
            @update:model-value="$emit('kubernetes_region', $event)"
            class="col-8"
          />
          <q-tooltip :delay="1000">
            This is the region where the Kubernetes cluster will be
            installed.<br />
            us-east-1 is the default region, and it applies to all cloud
            providers.
          </q-tooltip>     
        </div>
      </div>
      <div>
        <q-toggle
          class="col-1"
          :model-value="enable_feature_gates"
          :disable="kubernetesFlavour !== 'op' && kubernetesFlavour !== 'kind'"
          @update:model-value="$emit('enable_feature_gates', $event)"
          label="Enable Feature Gates"
          />
      </div>
      <transition name="slide-fade">
        <div v-if="enable_feature_gates" class="x">
          <div class="q-mt-lg">Custom tenant specs</div>
          <div class="row q-gutter-xl">
            <q-input
              stack-label
              label="Feature Gates"
              :model-value="feature_gates"
              @update:model-value="$emit('feature_gates', $event)"
              style="margin-bottom: 10px; width: 250px"
            />
          </div>
          </div>
        </transition>
      <div class="q-pa-md">
        <q-badge color="primary">
          CPU worker nodes: {{ workerNodeCount === null ? "Not selected" : workerNodeCount }}
          <br>
          If not selected, taking the default value
        </q-badge>

        <div>
          <q-slider
            v-model="workerNodeCount"
            @update:model-value="$emit('worker_node_count', $event)"
            :min="3"
            :max="20"
            :step="1"
            snap
            label
            markers
          />
          <q-tooltip :delay="1000">
            The number of CPU worker nodes to be installed.
          </q-tooltip>
        </div>
      </div>
      <q-stepper-navigation>
        <q-btn
          flat
          @click="$emit('update_step', 1)"
          color="primary"
          label="Back"
          class="q-ml-sm"
        />
        <q-btn @click="onClick" color="primary" label="Continue" />
      </q-stepper-navigation>
    </q-form>
  </q-step>
</template>

<script>
import k8sDistributionCard from "./k8s-distribution-card.vue";
import axios from "axios";
export default {
  components: {
    k8sDistributionCard,
  },
  methods: {
    onCardClicked(val) {
      this.$emit(
        "kubernetes_flavour",
        (this.kubernetesFlavour = val)
      );
      this.getK8sVersions(this.kubernetesFlavour);
      if (val !== "kind" && val !== "op") {
        this.$emit("enable_feature_gates", (this.enableFeatureGates = false));
      }
    },
    async getK8sVersions(distribution) {
      this.kubernetesVersion = "";
      this.kubernetesVersionOptions = await axios
        .get(
          `https://env-in-a-click-generator-ui.runailabs.com/api/k8s-versions?dist=${distribution}`
        )
        .then((response) => {
          return response.data;
        });
      this.$emit("kubernetes_version", this.kubernetesVersion);
    },
    onClick() {
      if (this.kuberbetesNotSelectedRule()) {
        this.$emit("update_step", 3);
      } else {
        this.showError = true;
      }
    },
    kuberbetesNotSelectedRule() {
      if (!this.kubernetesFlavour) {
        return false;
      }
      return true;
    },
  },
  props: {
    update_step: {
      type: Number,
      required: true,
    },
    kubernetes_version: {
      type: String,
      required: true,
    },
    kubernetes_region: {
      type: String,
      required: true,
    },
    worker_node_count: {
      type: Number,
      required: true,
    },
    kubernetes_flavour: {
      type: String,
      required: true,
    },
    enable_feature_gates: {
      type: Boolean,
      required: true,
    },
    feature_gates: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showError: false,
      k8sFlavoursOptions: [
        {
          title: "Kind",
          value: "kind",
          icon: "kind",
          description: "Local Kubernetes cluster on EC2",
        },
        {
          title: "GKE",
          value: "gcp",
          icon: "gke",
          description: "Google Kubernetes Engine",
        },
        {
          title: "EKS",
          value: "aws",
          icon: "eks",
          description: "Amazon Elastic Kubernetes Service",
        },
        {
          title: "RKE",
          value: "rke",
          icon: "rke",
          description: "Rancher Kubernetes Engine",
        },
        {
          title: "RKE2",
          value: "rke2",
          icon: "rke2",
          description: "Rancher Kubernetes Engine 2",
        },
        {
          title: "Openshift",
          value: "openshift",
          icon: "openshift",
          description: "OpenShift Container Platform",
        },
        {
          title: "Vanilla",
          value: "op",
          icon: "vanillak8s",
          description: "k8s native installation",
        },
        {
          title: "AKS",
          value: "aks",
          icon: "aks",
          description: "Azure Kubernetes Services",
        },
        {
          title: "BCM",
          value: "bcm",
          icon: "bcm",
          description: "Base Command Manager",
        },
        {
          title: "OKE",
          value: "oke",
          icon: "oke",
          description: "Oracle Kubernetes Engine",
        },
      ],
      region: this.kubernetes_region,
      workerNodeCount: this.worker_node_count,
      regionOptions: ["us-east-1", "eu-west-1"],
      kubernetesVersionOptions: this.getK8sVersions(this.kubernetes_flavour),
      kubernetesVersion: this.kubernetes_version,
      kubernetesFlavour: this.kubernetes_flavour,
      enableFeatureGates: this.enable_feature_gates,
      featureGates: this.feature_gates,

      step: this.update_step,
    };
  },
};
</script>
