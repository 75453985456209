<template>
  <q-step
      :name="4"
      title="GPU settings"
      caption="Optional"
      icon="settings"
      :done="this.update_step > 4"
  >
    <q-form ref="gpu-settings-form">
      <div class="row" style="margin-top: 30px">
        <div class="col-12">
          <q-select
              label="GPU installation type"
              v-model="gpuInstallationType"
              :options="gpuInstallationTypeOptions()"
              style="margin-bottom: 20px; width: 160px"
              @update:model-value="$emit('gpu_installation_type', $event)"
              class="col-8"
          />
          <q-tooltip :delay="1000">
            This is the type of GPU installation.<br />
            Fake - A fake GPU operator will be installed. (developed
            internally)<br />
            Real - A real (Nvidia) GPU operator will be installed.<br />
            None - No GPU operator will be installed.
          </q-tooltip>
        </div>

        <div class="col-12 row">
          <q-input
              v-if="gpuInstallationType === 'Fake'"
              type="number"
              label="GPU nodes count"
              :model-value="worker_node_count"
              style="margin-bottom: 10px; width: 160px; color: gray"
              disable
          />
          <q-input
              v-if="gpuInstallationType === 'Fake'"
              type="number"
              label="GPU count"
              v-model="fakeGPUCount"
              style="margin-bottom: 10px; width: 150px; margin-left: 30px"
              @update:model-value="$emit('fake_gpu_count', $event)"
              :rules="[fakeGPUCountRequiredRule]"
          />
          <q-tooltip :delay="1000">
            This is the number of GPUs per CPU worker node<br />
            If you have 2 CPU worker nodes and 16 GPUs per node, the total
            number of GPUs will be 32.
          </q-tooltip>

          <q-input
              v-if="gpuInstallationType === 'Fake'"
              type="number"
              label="GPU memory"
              v-model="fakeGPUMemory"
              style="margin-bottom: 10px; width: 150px; margin-left: 40px"
              @update:model-value="$emit('fake_gpu_memory', $event)"
              :rules="[fakeGPUMemoryRequiredRule]"
          />
          <q-tooltip :delay="1000">
            This is the amount of memory per GPU in GB.
          </q-tooltip>

          <q-select
              v-if="gpuInstallationType === 'Fake'"
              label="GPU product"
              v-model="fakeGPUProduct"
              :options="fakeGPUProductOptions"
              style="margin-bottom: 20px; width: 160px; margin-left: 40px"
              @update:model-value="$emit('fake_gpu_product', $event)"
              class="col-8"
          />
          <q-tooltip :delay="1000"> This is the GPU product. </q-tooltip>
        </div>

        <div class="col-12 row">
          <q-input
              v-if="gpuInstallationType === 'Real'"
              type="number"
              label="GPU node count"
              v-model="realGPUNodeCount"
              style="margin-bottom: 20px; width: 160px; margin-left: 0px"
              @update:model-value="$emit('real_gpu_node_count', $event)"
              :rules="[realGPUCountRequiredRule]"
          />
          <q-tooltip :delay="1000">
            This is the number of GPU nodes in addition to the CPU worker nodes.
          </q-tooltip>

          <q-select
              label="GPU product"
              v-if="gpuInstallationType === 'Real'"
              v-model="realGPUProduct"
              :options="realGPUProductOptionsFiltered()"
              style="margin-bottom: 10px; width: 150px; margin-left: 40px"
              @update:model-value="$emit('real_gpu_product', $event)"
              class="col-8"
          />
          <q-tooltip :delay="1000"> This is the GPU product. </q-tooltip>
        </div>
      </div>
      <div class="row items-center" v-for="(additionalGpu, idx) in additionalGpus" :key="idx">
        <div>
          <q-input
              v-if="gpuInstallationType !== 'None'"
              type="number"
              label="GPU nodes count"
              v-model="additionalGpu.gpuNodesCount"
              style="margin-bottom: 10px; width: 160px"
              @update:model-value="updateAdditionalGpus('gpuNodesCount', $event, idx)"
              :rules="[fakeGPUCountRequiredRule]"
          />
          <q-tooltip :delay="1000">
            This is the number of additional GPUs worker nodes.<br />
          </q-tooltip>
        </div>

        <div>
          <q-input
              v-if="gpuInstallationType === 'Fake'"
              type="number"
              label="GPU count"
              v-model="additionalGpu.gpuCount"
              style="margin-bottom: 10px; width: 150px; margin-left: 30px"
              @update:model-value="updateAdditionalGpus('gpuCount', $event, idx)"
              :rules="[fakeGPUCountRequiredRule]"
          />
          <q-tooltip :delay="1000">
            This is the number of GPUs per CPU worker node<br />
            If you have 2 CPU worker nodes and 16 GPUs per node, the total
            number of GPUs will be 32.
          </q-tooltip>
        </div>

        <div>
          <q-input
              v-if="gpuInstallationType === 'Fake'"
              type="number"
              label="GPU memory"
              v-model="additionalGpu.gpuMemory"
              style="margin-bottom: 10px; width: 150px; margin-left: 40px"
              @update:model-value="updateAdditionalGpus('gpuMemory', $event, idx)"
              :rules="[fakeGPUMemoryRequiredRule]"
          />
          <q-tooltip :delay="1000">
            This is the amount of memory per GPU in GB.
          </q-tooltip>
        </div>

        <div>
          <q-select
              label="GPU product"
              v-if="gpuInstallationType === 'Fake'"
              v-model="additionalGpu.gpuProduct"
              :options="fakeGPUProductOptions.filter(option => !this.additionalGpus.some(gpu => gpu.gpuProduct === option) && option !== fake_gpu_product)"
              style="margin-bottom: 30px; width: 160px; margin-left: 40px"
              @update:model-value="updateAdditionalGpus('gpuProduct', $event, idx)"
              class="col-8"
          />
          <q-tooltip :delay="1000"> This is the GPU product. </q-tooltip>
        </div>
        <div>
          <q-select
              label="GPU product"
              v-if="gpuInstallationType === 'Real'"
              v-model="additionalGpu.gpuProduct"
              :options="realGPUProductOptions.filter(option => !this.additionalGpus.some(gpu => gpu.gpuProduct === option) && option !== real_gpu_product)"
              style="margin-bottom: 30px; width: 160px; margin-left: 40px"
              @update:model-value="updateAdditionalGpus('gpuProduct', $event, idx)"
              class="col-8"
          />
          <q-tooltip :delay="1000"> This is the GPU product. </q-tooltip>
        </div>
        <q-btn class="q-ml-md" flat color="red" size="sm" icon="delete" @click="removeAdditionalGpu(idx)"></q-btn>
      </div>
      <q-btn v-if="gpuInstallationType === 'Real'" no-caps label="+ Additional GPU" flat color="primary" @click="addAdditionalGpu(real_gpu_product)"/>
      <q-btn v-if="gpuInstallationType === 'Fake'" no-caps label="+ Additional GPU" flat color="primary" @click="addAdditionalGpu(fake_gpu_product)"/>


      <q-stepper-navigation>
        <q-btn
            flat
            @click="$emit('update_step', 3)"
            color="primary"
            label="Back"
            class="q-ml-sm"
        />
        <q-btn @click="onClick" color="primary" label="Continue" />
      </q-stepper-navigation>
    </q-form>
  </q-step>
</template>

<script>

export default {
  components: {},
  watch: {
    gpuInstallationType(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.localAdditionalGpus = [];
        this.$emit('updateAdditionalGpus', this.localAdditionalGpus);
      }
    }
  },
  methods: {
    gpuInstallationTypeOptions() {
      if (this.kubernetes_flavour === "kind") {
        if (this.gpuInstallationType === "Real") {
          this.gpuInstallationType = "Fake";
          this.$emit("gpu_installation_type", this.gpuInstallationType);
        }
        return ["Fake", "None"];
      }
      return ["Fake", "Real", "None"];
    },
    realGPUProductOptionsFiltered() {
      var gpuProduct = this.realGPUProductOptions;
      if (this.kubernetes_flavour === "gcp") {
        if (this.realGPUProduct === "A10-1") {
        this.realGPUProduct = "T4-1";
        this.$emit("real_gpu_product", this.realGPUProduct);
        gpuProduct.pop("A10-1");
        return gpuProduct;
      }}
      return gpuProduct;
    },
    async onClick() {
      if (this.gpuInstallationType === "Fake") {
        this.$refs["gpu-settings-form"].validate().then((success) => {
          if (success) {
            this.$emit("update_step", 5);
          }
        });
      } else if (this.gpuInstallationType === "Real") {
        this.$refs["gpu-settings-form"].validate().then((success) => {
          if (success) {
            this.$emit("update_step", 5);
          }
        });
      } else {
        this.$emit("update_step", 5);
      }
    },
    fakeGPUCountRequiredRule: (val) => val > 0 || "Required",
    fakeGPUMemoryRequiredRule: (val) => val > 0 || "Required",
    realGPUCountRequiredRule: (val) => val > 0 || "Required",
    addAdditionalGpu(gpu){
      if (this.gpuInstallationType === 'Real') {
        this.$emit('updateAdditionalGpus', [...this.additionalGpus, {
          gpuNodesCount: 1,
          gpuInstallationType: 'Real',
          gpuProduct: this.realGPUProductOptions.filter(option => !this.additionalGpus.some(gpu => gpu.gpuProduct === option) && option !== gpu)[0],
        }])
      } else {
        this.$emit('updateAdditionalGpus', [...this.additionalGpus, {
          gpuNodesCount: 1,
          gpuInstallationType: 'Fake',
          gpuCount: 16,
          gpuMemory: 12000,
          gpuProduct: this.fakeGPUProductOptions.filter(option => !this.additionalGpus.some(gpu => gpu.gpuProduct === option) && option !== gpu)[0],
        }])
      }
    },
    updateAdditionalGpus(key, value, idx){
      this.$emit('updateAdditionalGpus', this.additionalGpus.map((gpu, i) => i === idx ? {...gpu, [key]:value} : gpu))
    },
    removeAdditionalGpu(idx){
      this.$emit('updateAdditionalGpus', this.additionalGpus.filter((_, i) => i !== idx))
    }
  },
  props: {
    gpuTypeOptions: {
      type: Array,
      required: true
    },
    update_step: {
      type: Number,
      required: true,
    },
    fake_gpu_count: {
      type: Number,
      required: true,
    },
    fake_gpu_memory: {
      type: Number,
      required: true,
    },
    fake_gpu_product: {
      type: String,
      required: true,
    },
    real_gpu_node_count: {
      type: Number,
      required: true,
    },
    real_gpu_product: {
      type: String,
      required: true,
    },
    gpu_installation_type: {
      type: String,
      required: true,
    },
    kubernetes_flavour: {
      type: String,
      required: true,
    },
    additionalGpus: {
      type: Array,
      required: true,
    },
    worker_node_count: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      options: [],
      localAdditionalGpus: [...this.additionalGpus],
      fakeGPUCount: this.fake_gpu_count,
      fakeGPUMemory: this.fake_gpu_memory,
      fakeGPUProduct: this.fake_gpu_product,
      realGPUNodeCount: this.real_gpu_node_count,
      fakeGPUProductOptions: [
        "Tesla-V100",
        "Tesla-P100",
        "Tesla-T4",
        "NVIDIA-A100-SXM4-40GB",
        "NVIDIA-A100-SXM4-80GB",
      ],
      realGPUProduct: this.real_gpu_product,
      realGPUProductOptions: [
        "T4-1",
        "T4-4",
        "A10-1",
        "Tesla V100-1",
        "Tesla V100-4",
        "Tesla A100-40GB-8",
        "Tesla A100-80GB-8",
      ],
      gpuInstallationType: this.gpu_installation_type,
      step: this.update_step,
    };
  },
};
</script>
